import React from 'react';
import { Navigate } from 'react-router-dom';

// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
  // Check if the user is authenticated by looking for a flag in localStorage
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  // If authenticated, render the children components (protected pages)
  // If not authenticated, navigate to the login page
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
